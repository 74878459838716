import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// API
import Http from '@/ship/Http';
import { readChatMessages } from '@/utils/api/Chats';

// Interfaces
import User from '@/ship/Models/User';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { IChat, IChatMessage, IChatOptions, IChatPendingMessage } from '@/ship/Models/Chats';

// Utils
import { clone, get } from 'lodash';
import ErrorService from '@/utils/ErrorService';
import { AxiosError } from 'axios';

@Component
export default class VChatMixin extends Vue {
    @Prop({ type: Object, default: () => ({}) })
    public options!: IChatOptions;

    public chat: IChat | null = null;
    public pendingMessages: IChatPendingMessage[] = [];

    loading = false;

    private interval: NodeJS.Timeout | null = null;
    private readonly REFRESH_DELAY = 3000;

    private readonly defaultChatModel = {
        id: 0,
        messages: { data: [] },
        users: { data: [] },
        unreadedCount: 0,
    };

    get messages(): IChatMessage[] {
        return get(this.chat, 'messages.data', []);
    }

    get users(): User[] {
        const chatUsers: User[] = get(this.chat, 'users.data', []);
        const messageUserIds = new Set(this.messages.map((message) => message.userId));
        const users = chatUsers.filter((user) => messageUserIds.has(user.id));
        return users;
    }

    // Hooks
    public hookNewMessages() {
        return;
    }

    public hookSendPendingMessage() {
        return;
    }

    // Actions
    public async getUnreadCount(): Promise<number | undefined> {
        if (this.options.chatId) {
            const response = await Http.get<ITransformedValue<Pick<IChat, 'unreadedCount'>>>(this.options.getEndpoint, {
                params: { filter: 'unreaded_count' },
            });

            return response.data.data.unreadedCount;
        }
    }

    public async sendMessage(message: string) {
        const { sendEndpoint } = this.options;

        try {
            const response = await Http.post<ITransformedValue<IChat>>(sendEndpoint, {
                message,
            });

            this.pendingMessages.pop();
            this.chat = response.data.data;
            if (!this.options.chatId) this.$emit('update-chat', this.chat.id);
        } catch (error) {
            const pendingMessage: IChatPendingMessage = {
                id: -this.pendingMessages.length,
                body: message,
                error: this.$t('components.chat.failedToSendMessage'),
            };
            this.pendingMessages.push(pendingMessage);
            this.hookSendPendingMessage();
        } finally {
            this.setRefreshingMode(true);
        }
    }

    public async loadMessages(forceLoad = false) {
        const unreadCount = await this.getUnreadCount();
        if (!forceLoad && !unreadCount) return;

        const { getEndpoint } = this.options;
        const previousMessagesLength = this.chat?.messages.data.length;

        try {
            if (this.options.chatId) {
                const response = await Http.get<ITransformedValue<IChat>>(getEndpoint);

                this.chat = response.data.data;

                await readChatMessages(this.chat.id);

                if (previousMessagesLength && previousMessagesLength < this.chat.messages.data.length) {
                    this.hookNewMessages();
                }
            } else {
                this.chat = clone(this.defaultChatModel);
                this.setRefreshingMode(false);
            }
        } catch (error) {
            const axiosError = error as AxiosError;

            // Chat not exist
            if (error && axiosError.response && axiosError.response.status === 404) {
                this.chat = clone(this.defaultChatModel);
            }

            this.setRefreshingMode(false);
        }
    }

    private async setRefreshingMode(active: boolean) {
        try {
            this.loading = true;

            if (active && !this.interval) {
                await this.loadMessages(true);
                this.interval = setInterval(this.loadMessages, this.REFRESH_DELAY);
            }

            if (!active && this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        } catch (error) {
            if (get(error, 'response.data.message') === 'Chat is not found') {
                return;
            } else {
                ErrorService.handleApiError(error);
            }
        } finally {
            this.loading = false;
        }
    }

    public closeChat() {
        this.$emit('closeChat');
        this.setRefreshingMode(false);
    }

    // Lifecycle hooks
    private created() {
        this.setRefreshingMode(true);
    }

    private beforeDestroy() {
        this.setRefreshingMode(false);
    }

    @Watch('options')
    async optionsChangeHandler() {
        await this.loadMessages(true);
    }
}
